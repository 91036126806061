/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/* @font-face {
  font-family: AmaticSC;
  src: url('./assets/font/AmaticSC.ttf');
  font-weight: 400;
}
@font-face {
  font-family: AmaticSC;
  src: url('./assets/font/AmaticSC-Bold.ttf');
  font-weight:700;
}
@font-face {
  font-family: GochiHand;
  src: url('./assets/font/GochiHand-Regular.ttf');
  font-weight:400;
} */

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/************ Navbar Css ************/
.home__img {
  background: url(../src/assets/img/profile.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
  order: 1;
  justify-self: center;
  width: 300px;
  height: 300px;
  animation: profile__animate 8s ease-in-out infinite 1s;
}

@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

/* For large devices */
@media screen and (max-width: 992px) {
  .home__img {
      width: 250px;
      height: 250px;
      box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%);
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .home__img {
      box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
      width: 200px;
      height: 200px;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .home__img {
    width: 180px;
    height: 180px;
  }
}


.home-logo{
  color: #fff !important;
  font-size: 50px;
  font-weight: bold;

}
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
  width: 9%;
}

.navbar-brand {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ login Css ************/
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 80px 50px;
  width: 50% ;
  max-width: 600px;
  height: auto;
  margin: auto;
  justify-content: center;
  transform: translateY(150px);
}

.Loginpage-button {
  border-color: #0dd4c3 !important;
  ;
  color:#0dd4c3!important;
  ;
  background-color: transparent !important;
}

.Loginpage-button:hover {
  background-color: #0dd4c3 !important;
  color: white !important;
}

.Loginpage-signup-button{
    color:#0dd4c3 !important
}



/************ Signup Css ************/
.signup-container {
  display: flex;
  justify-content: center;
  align-items: center ;
  flex-direction: column;
  background: #0dd4c3;
  border-radius: 55px;
  color: white;
  padding: 80px 50px;
  width: 50%;
  max-width: 600px;
  height: auto;
  margin: auto;
  justify-content: center;
  transform: translateY(150px);
}

.signup-button {
  border-color: white !important;
  ;
  color:white !important;
  ;
  background-color: transparent !important;
}

.signup-button:hover {
  background-color: white !important;
  color: #0dd4c3 !important;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/banner-bg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(13, 212, 195, 0.5) -5.91%, rgba(188, 25, 161, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}


.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner a {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 25px;
}

.banner a svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner a:hover svg {
  margin-left: 25px;
}


.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}


/************ Skills Css ************/
.quadrant-text {
  position: absolute;
  color: white;
  font-size: 1.5rem;
  z-index: 20;
  background-color: #151515;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
  clip-path: polygon(10% 0, 90% 0,
      /* 顶部边界，10% 和 90% 从两侧内缩 */
      95% 25%, 95% 75%,
      /* 右侧边界，从顶部和底部分别向内缩 25% 和 75%，向左缩 5% */
      90% 100%, 10% 100%,
      /* 底部边界，同顶部 */
      5% 75%, 5% 25%
      /* 左侧边界，从顶部和底部分别向内缩 25% 和 75%，向右缩 5% */
    );
}


.axis-x,
.axis-y {
  position: absolute;
  background-color: rgb(86, 4, 86);
}

.axis-x {
  width: 100%;
  height: 1px;
  top: 50%;
  left: 0;
}

.axis-y {
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
}

.center-icon {
  position: absolute;
  font-size: 3rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  /* border: 1px solid #ccc; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* Adjust position to be truly centered */
}

.center-icon p {
  margin-top: 5px;
  font-size: 1rem;
  color: #ffffff;
  text-align: center;
}

.icon {
  position: absolute;
  width: 70px;

  /* border: 1px solid #ccc; */
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
}


.icon img {
  width: 100%;
  object-fit: contain;
}

.icon p {
  margin-top: 5px;

  font-size: 1rem;
  color: #ffffff;
  text-align: center;
}

.icon div:last-child {
  font-size: 0rem;
  /* 调整文字大小 */
}

@keyframes expand {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.5);
  }
}

.ellipse {
  position: absolute;
  border: 1px solid rgb(86, 4, 86);
  border-radius: 50%;

  animation: expand 2s forwards;
}

.ellipse1 { width: 200px; height: 100px; }
.ellipse2 { width: 400px; height: 200px; }
.ellipse3 { width: 600px; height: 300px; }
.ellipse4 { width: 800px; height: 400px; }
.ellipse5 { width: 1000px; height: 500px; }
.ellipse6 {
  width: 1200px;
  height: 600px;
}

/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project span {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: left;
  width: 56%;
}
.row-flex-cards {
  display: flex;
  flex-wrap: wrap;
}

.col-flex-cards {
  display: flex;
  flex-direction: column;
}

.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #0dd4c3 -5.91%, #91198b 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#projects-tabs-tab-all {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-web {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-mobile {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}

.proj-imgbx {
  height: 260px;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;

}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #0dd4c3 10%, #91198b 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top:65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.proj-txtx a {
  color: #ADB7BE;
  transition: color 0.3s ease;
}

.proj-txtx a:hover {
  color: white;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, #0dd4c3 -5.91%, #91198b 111.58%);
  padding: 60px 0 200px 0;
}

.contact img {
  width: 92%;
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}

/************ Tag Css ************/

.comment-tag {
  display: inline-block;
  padding: 3px 8px;
  margin: 2px;
  background-color: #e0f0ff;

  color: #333;
  border-radius: 10px;
  font-size: 0.8em;
}

.tag-count {
  font-weight: bold;
  color: #555;
  margin-right: 10px;
}
/************ Addcomment Css ************/

.Addcomment {
  max-width: 1100px;
  margin: 20px auto;
  padding: 10px;
  border-radius: 8px;
  background-color: white;

  min-height: fit-content;
  margin-top: 100px
}

.Addcomment-form {
  display: flex;
  flex-direction: column;

}


.Submit-button{
  
border-color: #0dd4c3 !important;
color: #0dd4c3 !important;
background-color: transparent !important;
}
  
.Submit-button:hover {
  background-color: #0dd4c3 !important;
  color: white !important;
}

.rating {
  display: flex;
}

/* Tag Styles */
.tags-input {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag-button {
  padding: 5px 10px;
  border: none;
  background-color: #a6e9e3;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tag-button.selected {
  background-color: #0dd4c3;
}

.tag-button:hover {
  background-color: #0dd4c3;
}
.tags-input-empty{
  border-radius: 10px;
  border-color: #a6e9e3;
}


.alert {
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
}

/************ Commenttable Css ************/
.commentable-container {
  /* Styles for the overall container */
  padding: 20px;
  max-width: 1100px;
  color:#000;
  transform: translateY(80px);
  margin: 20px auto;
  padding: 10px;
  border-radius: 8px;
  background-color: white;
 
  min-height: fit-content;
  display: flex;
  flex-direction: column;
}

.ratings-summary {
  /* Styles for the ratings summary section */
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  max-width: 1100px;

}

.total-ratings,
.average-rating {
  /* Styles for total ratings and average ratings */
  margin-bottom: 10px;
  font-size: 18px;
}

.tag-summary {
  /* Styles for the tag summary section */
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.tag-summary-button {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #a6e9e3;
  border-radius: 15px;
}

.comment-tag {
  /* Styles for individual tags in comments */
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #a6e9e3;
  border-radius: 15px;
}
/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}

.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}

.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}

.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #0dd4c3 -5.91%, #ff3366 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}

.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}

.new-email-bx button {
  background: linear-gradient(90.21deg, #0dd4c3 -5.91%, #91198b 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}

.footer img {
  width: 26%;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}